import { ethers } from 'ethers';
import axios from 'axios';
import { loadBattlePvpData } from './getAdvRenownByDate'; // Import procedure to fetch renownSum
import { API_BASE_URL } from '../constants/constants';

const API_KEY = 'XCAJG2DVHPAPIY116GAFR2UI3VCYKC539E';
const FIGHT_CONTRACT_ADDRESS = '0x2cfcaff3289142E79173B856293D6128B6bD05c6';
const ANIMA_CONTRACT_ADDRESS = '0xccd05a0fcfc1380e9da27862adb2198e58e0d66f';
const ARBISCAN_API_URL = 'https://api.arbiscan.io/api';

const graphEndpoint = API_BASE_URL;

interface TransactionData {
  date: string;
  animaSum: number;
  renownSum: number;
  transactionFeeSum: number;
  lootboxesCount: number;
  passiveRenown: number; // New optional field for passiveRenown
}

const fetchCurrentEthPrice = async (): Promise<number> => {
  try {
    const response = await axios.get(`${ARBISCAN_API_URL}`, {
      params: {
        module: 'stats',
        action: 'ethprice',
        apikey: API_KEY,
      },
    });
    return parseFloat(response.data.result.ethusd);
  } catch (error) {
    console.error('Error fetching ETH price from Arbiscan:', error);
    throw new Error('Failed to fetch ETH price');
  }
};

// New function to fetch passiveRenown data from the Graph API


export const fetchPassiveRenownData = async (address: string): Promise<{ [date: string]: number }> => {
  const addressLowerCase = `0x${address.slice(2).toLowerCase()}`;

  // Calculate today's date in days since the epoch
  const todayInDays = Math.floor(Date.now() / 86400000);
  const startDateInDays = todayInDays - 31; // Starting point for 31 days ago

  // Initialize renownByDate with all dates in the range set to 0
  const renownByDate: { [date: string]: number } = {};
  for (let i = 0; i <= 31; i++) {
    const date = new Date((startDateInDays + i) * 86400000).toISOString().split('T')[0];
    renownByDate[date] = 0; // Ensures all dates within the range are initialized
  }

  // Define the size of each batch in days
  const batchSize = 3;
  let currentStartDay = startDateInDays;

  // Loop to fetch data in 3-day batches until we reach today
  while (currentStartDay <= todayInDays) {
    const currentEndDay = Math.min(currentStartDay + batchSize - 1, todayInDays);
    let hasMoreData = true;
    let page = 0;

    // Paginate within each 3-day interval
    while (hasMoreData) {
      const { data } = await axios.post(graphEndpoint, {
        operationName: 'passiveRenown',
        query: `
          query passiveRenowns($skip: Int) {
            battlePvps(
              where: { 
                opponentRenown_not: 0, 
                fightEpoch_gte: ${currentStartDay},
                fightEpoch_lte: ${currentEndDay},
                contender2_: { owner: "${addressLowerCase}" } 
              },
              orderBy: createdAt,
              orderDirection: desc,
              skip: $skip,
              first: 10000
            ) {
              fightEpoch,
              opponentRenown
            }
          }
        `,
        variables: {
          skip: page * 10000, // Adjust skip to paginate through results
        },
      });

      const battlePvps = data.data.battlePvps;
      console.log(`Data Passive Renown Array for days ${currentStartDay} to ${currentEndDay}, page ${page}:`, battlePvps);

      // Check if we have more data to fetch
      hasMoreData = battlePvps.length === 10000; // Continue if we got a full page (assuming 10,000 as a page size)

      // Process each battle in the batch and add renown to the respective date in `renownByDate`
      battlePvps.forEach((battle: any) => {
        const date = new Date(battle.fightEpoch * 86400000).toISOString().split('T')[0];
        renownByDate[date] += Math.floor(Number(battle.opponentRenown) / 1000); // Accumulate passive renown
      });

      page += 1; // Move to the next page for pagination
    }

    // Move to the next 3-day interval
    currentStartDay += batchSize;
  }

  // Final output will contain every date in the 31-day range with accumulated passive renown data
  console.log("Final Data Passive Renown Array with All Dates:", renownByDate);

  return renownByDate;
};



export const fetchFightsData = async (address: string): Promise<TransactionData[]> => {
  const currentEthPrice = await fetchCurrentEthPrice();
  const oneMonthAgo = Math.floor(Date.now() / 1000) - (31 * 24 * 60 * 60);

  const fightTxUrl = `${ARBISCAN_API_URL}?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&page=1&offset=10000&sort=desc&apikey=${API_KEY}`;
  const animaTxUrl = `${ARBISCAN_API_URL}?module=account&action=tokentx&address=${address}&contractaddress=${ANIMA_CONTRACT_ADDRESS}&page=1&offset=10000&startblock=0&endblock=99999999&sort=desc&apikey=${API_KEY}`;
  
  console.log("Fight Tx API URL:", fightTxUrl);
  console.log("Anima Tx API URL:", animaTxUrl);

  const fightResponse = await axios.get(fightTxUrl);
  const animaResponse = await axios.get(animaTxUrl);

  if (fightResponse.data.status !== "1" || !fightResponse.data.result || animaResponse.data.status !== "1" || !animaResponse.data.result) {
    console.error("Error fetching data:", fightResponse.data.message, animaResponse.data.message);
    return [];
  }

  const fightTransactions = fightResponse.data.result.filter((tx: any) =>
    Number(tx.timeStamp) >= oneMonthAgo &&
    tx.txreceipt_status === "1" &&
    tx.functionName === "fight(tuple _request)"
  );

  const animaTransactions = animaResponse.data.result.filter((tx: any) =>
    Number(tx.timeStamp) >= oneMonthAgo &&
    tx.from === '0x0000000000000000000000000000000000000000'
  );

  console.log("Filtered Fight Transactions:", fightTransactions);
  console.log("Filtered Anima Transactions:", animaTransactions);

  const renownData = await loadBattlePvpData(address);
  const passiveRenownData = await fetchPassiveRenownData(address); // Fetch passiveRenown data

  const transactionData: { [date: string]: Omit<TransactionData, 'date'> } = {};

  for (const tx of fightTransactions) {
    const animaTx = animaTransactions.find((animaTx: any) => animaTx.hash === tx.hash);

    if (animaTx) {
      const date = new Date(Number(tx.timeStamp) * 1000).toISOString().split('T')[0];
      if (!transactionData[date]) {
        transactionData[date] = { animaSum: 0, renownSum: 0, transactionFeeSum: 0, lootboxesCount: 0, passiveRenown: 0 };
      }

      transactionData[date].animaSum += Number(ethers.utils.formatUnits(animaTx.value, 18));
      transactionData[date].transactionFeeSum += (Number(ethers.utils.formatEther(tx.gasUsed)) * currentEthPrice) * 1e7;
    }
  }

  const result: TransactionData[] = Array.from(new Set([...Object.keys(transactionData), ...Object.keys(passiveRenownData)]))
  .map(date => {
    const { animaSum = 0, transactionFeeSum = 0, lootboxesCount = 0 } = transactionData[date] || {};
    const renownRecord = renownData.find((r) => r.date === date);
    const passiveRenown = passiveRenownData[date] || 0;
    return {
      date,
      animaSum,
      renownSum: renownRecord ? renownRecord.renownSum : 0,
      transactionFeeSum,
      lootboxesCount: renownRecord ? renownRecord.lootboxesCount : 0,
      passiveRenown,
    };
  })
  .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()); // Sort by date in descending order

return result;
};












