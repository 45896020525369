import { ConnectButton } from '@rainbow-me/rainbowkit';
import Link from 'next/link';
import AnimaBalanceDisplay from '../../dai/components/AnimaBalanceDisplay'; // Import the AnimaBalanceDisplay component
import { useState } from 'react';

export function Header() {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div>
      <div className="header-wrapper">
        <div className="header">
          <div className="left">
            <h1>
              <Link href="/">
                <div
                  style={{ marginLeft: '5px', color: 'white', position: 'relative' }}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <h1 style={{ color: 'white' }}>MAFIA AutoBattler ©AM v1.9</h1>
                  {showTooltip && (
                    <div className="tooltip">
                      <ul>
                      <li><strong>v1.9</strong><span> AB version tooltip added</span></li>
                      <li><strong>v1.8</strong><span> corrected opponents count and materials tooltip display</span></li>
                      <li><strong>v1.7</strong><span> passive Renown added to battles stats</span></li>
                      <li><strong>v1.6</strong><span> balance slider saved as default; ratio column and sorting added; Smol bodies included</span></li>
                      <li><strong>v1.5</strong><span> Renown and lootboxes added to battles stats; top batch button introduced; added check for realm readiness to ensure updated adventurer data after fights</span></li>
                      <li><strong>v1.4</strong><span> slot order sorting feature implemented</span></li>
                      <li><strong>v1.3</strong><span> balance chart functionality introduced</span></li>
                      <li><strong>v1.2</strong><span> battle mode selector removed; balance slider added</span></li>
                      <li><strong>v1.1</strong><span> subscription expiry date and renewal button added</span></li>

                      </ul>
                    </div>
                  )}
                </div>
              </Link>
            </h1>
          </div>
          <div className="right">
            <AnimaBalanceDisplay /> {/* Add the AnimaBalanceDisplay component */}
            <div className="login-box">
              <ConnectButton
                accountStatus={{
                  smallScreen: 'avatar',
                  largeScreen: 'full'
                }}
                showBalance={false} // Hide the ETH balance
                chainStatus="none" // Hide the network status to prevent changing
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .header-wrapper {
          text-transform: uppercase;
          position: fixed;
          top: 0;
          width: 100%;
          left: 0;
          z-index: 1000;
        }

        .header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          flex-wrap: wrap;
          background: var(--black-background);
          padding: 5px;
        }

        .links {
          display: flex;
          justify-content: space-between;
          max-width: 800px;
          margin: 0 auto;
          font-size: 12px;
          padding-left: 15px;
          padding-right: 15px;
          flex-wrap: wrap;
          align-items: center;
        }

        .link {
          margin-left: 15px;
          margin-right: 15px;
          font-size: 12px;
        }

        a {
          color: white;
        }

        h1 {
          padding: 0;
          margin: 0;
          font-size: 14px;
          text-transform: none; /* Reset text-transform for this h1 */
        }

        h1 a {
          color: white;
          text-decoration: none;
          display: flex;
          align-items: center;
        }

        .tooltip {
          position: absolute;
          top: 100%; /* Position the tooltip just below the header text */
          left: 0;
          background-color: #c3c356;
          color: black;
          padding: 10px;
          border-radius: 4px;
          font-size: 12px;
          width: 250px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          z-index: 1000;
          text-align: left;
        }

        .tooltip ul {
          padding: 0;
          margin: 0;
          list-style: none;
          text-transform: none; /* Ensure list items are in normal text */
        }

        .tooltip li {
          margin-bottom: 4px;
        }

        .tooltip li span {
          font-weight: normal;
          font-size: 90%; /* Set the rest of the text to 90% of the default size */
        }

        @media only screen and (max-width: 600px) {
          h1 img {
            max-width: 100px;
          }

          .header .links {
            display: none;
          }
        }

        .right {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .network {
          pointer-events: none; /* Disable clicking */
        }
      `}</style>
    </div>
  );
}











